/* You can add global styles to this file, and also import other style files */

@import 'sass/variables';

@import '@exb/theme/scss/theme';

@import 'sass/custom.scss';

body,
html {
  height: 100%;
  margin: auto;
}
