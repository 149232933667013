@use '@exb/theme/scss/mixins' as *;

// Avatar classes
.avatar-s {
  @include set-typography-style(Inter, 2.5 * $space-unit, 500, 3.75 * $space-unit, var(--color-text-invers));
  border: 1px solid var(--color-border-invers);
  width: 6 * $space-unit !important;
  height: 6 * $space-unit !important;
  font-size: 2.5 * $space-unit !important;
}

.avatar-md {
  @include set-typography-style(Inter, 2.5 * $space-unit, 500, 3.75 * $space-unit, var(--color-text-invers));
  border: 1px solid var(--color-border-invers);
  font-size: 2.5 * $space-unit !important;
}

.pointer-events-auto {
  pointer-events: auto;
}

.shadow-small {
  box-shadow: $smallShadow;
}

.shadow-medium {
  box-shadow: $mediumShadow;
}

.shadow-large {
  box-shadow: $largeShadow;
}

.z-index-top-level {
  z-index: 9999;
}

.p-link {
  @include set-typography-style(Inter-SemiBold, 12px, 600, 18px, var(--color-text-primary));
  text-decoration: none;
}

.info-text-container a {
  @extend .p-link;
}

// material-icons
/**  General style affecting material icons  **/
.material-icons {
  font-size: $space-gap-m;
}
.material-icons-round,
.material-icons-outlined {
  font-size: $space-gap-m;
}

// make raw-on icon bigger for better readability
.material-icons-round.mi-raw-on {
  font-size: 5.25 * $space-unit;
  left: -0.5 * $space-unit;
  position: relative;
  top: -0.75 * $space-unit;
}

// close,hide the close icon inside an input
.hide-close {
  display: none;
}
.show-close {
  cursor: pointer;
  display: block;
}

.field > small {
  $size: 10px;
  $inputBorderWidth: 1px;

  display: flex !important;
  flex-direction: row;
  align-items: center;

  margin-top: $inputBorderWidth;
  font-size: $size;
  line-height: $space-unit * 3 + 3;

  &:not(.p-error) {
    color: var(--color-text-muted);
  }

  &.p-error::before {
    @extend .material-icons-round, .mi-error;
    margin-right: $space-unit;
    font-size: $size;
  }
}

.field > .p-float-label ~ small {
  padding-left: 2 * $space-unit;
}

// quality score classes
.qa-score {
  width: $space-gap-xxs * 7;
  height: $space-gap-xxs * 7;
  background: var(--color-icon-muted);
  border-radius: 50%;
  display: inline-block;
}

.qa-success {
  background: var(--color-icon-success-standalone);
}

.qa-severe {
  background: var(--color-icon-error-standalone);
}

.qa-warning {
  background: var(--color-icon-warning-standalone);
}

.qa-unavailable {
  background: var(--color-icon-muted);
}

.form-separator {
  display: flex;
  align-items: center;

  hr {
    flex: 1;
    margin: 0;
    border: none;
    border-top: 1px solid var(--color-text-moderate);
  }

  span {
    margin: 0 $space-gap-m;
    color: var(--color-text-moderate);
  }
}

// Mini header of apps like in dem, document viewer
.mini-header {
  height: 12 * $space-unit;
  min-height: 12 * $space-unit;
  padding: $space-gap-s $space-gap-m;
  border-bottom: 1px solid var(--color-border-muted);
}
.custom-button-stepper {
  .step {
    background: var(--color-background-subtle-hover);
    border-radius: 50%;
    border-color: var(--color-background-subtle-hover);
    color: var(--color-text-muted);
  }
  .step-line {
    border-top: 1px solid var(--color-background-subtle-hover);
    width: 6 * $space-unit;
  }
  .active {
    background: var(--color-border-primary);
    border-color: var(--color-border-primary);
    color: var(--color-text-invers);
  }
  .checked {
    border-color: var(--color-border-primary);
    color: var(--color-border-primary);
  }
}

.gradient-background {
  background: linear-gradient(180deg, rgba(91, 30, 253, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
}

.white-space-break {
  white-space: break-spaces;
}

.max-width-50rem {
  max-width: 50rem;
}

.max-width-50-percent {
  max-width: 50% !important;
}

.border-grey-20 {
  border-color: var(--color-neutral-grey-20);
}

.line-height-18 {
  line-height: 18px;
}

button.reset-styling {
  all: unset;
  box-sizing: border-box;
}

.overlay-title {
  @include set-typography-style(Inter, 10px, 600, 15px, var(--color-text-highlight));
}

.p-dynamic-dialog.dialog-with-inner-vertical-scrollbar {
  .p-dialog-content {
    &,
    *:has(.overflow-y-auto) {
      overflow-y: clip;
      display: flex;
      flex-direction: column;
    }

    *:has(.overflow-y-auto) {
      flex-grow: 1;
    }
  }
}

mark.highlighted-substring {
  font-family: $font-family-bold, sans-serif;
  font-weight: 700;
  background: unset;
}
